/*
 * Copyright (C) 2024 - present Instructure, Inc.
 *
 * This file is part of Canvas.
 *
 * Canvas is free software: you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License as published by the Free
 * Software Foundation, version 3 of the License.
 *
 * Canvas is distributed in the hope that it will be useful, but WITHOUT ANY
 * WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 * A PARTICULAR PURPOSE. See the GNU Affero General Public License for more
 * details.
 *
 * You should have received a copy of the GNU Affero General Public License along
 * with this program. If not, see <http://www.gnu.org/licenses/>.
 */

import type {PasswordPolicy} from '../types'

export const usePasswordValidator = (passwordPolicy?: PasswordPolicy) => {
  if (!passwordPolicy) {
    return (): string | null => null
  }

  return (password: string | undefined): string | null => {
    if (!password) return 'too_short'

    const {
      minimumCharacterLength = 0,
      requireNumberCharacters,
      requireSymbolCharacters,
    } = passwordPolicy

    if (password.length < minimumCharacterLength) {
      return 'too_short'
    }

    if (requireNumberCharacters && !/\d/.test(password)) {
      return 'no_digits'
    }

    if (requireSymbolCharacters && !/[!@#$%^&*()_+\-=[\]{}|;:'"<>,.?/]/.test(password)) {
      return 'no_symbols'
    }

    return null
  }
}
